export const Logo = ({ className = 'w-28 2xs:w-36 sm:w-52 lg:w-64 2xl:w-80', fill = '#000000' }) => {
  return (
    <svg
      viewBox="0 0 1495.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" stroke="none" fill={fill}>
        <path
          d="M8951 2223 c-1 -409 -5 -743 -10 -743 -4 0 -65 59 -135 130 -89 91
-126 136 -122 148 3 9 27 121 55 247 28 127 80 360 115 520 57 253 67 312 52
294 -3 -4 -72 -218 -238 -744 -38 -121 -73 -230 -78 -241 -10 -25 -19 -28
-706 -238 -227 -69 -417 -126 -423 -126 -7 0 -9 -4 -6 -10 4 -7 263 -10 743
-10 l737 0 -114 -114 c-63 -62 -123 -119 -133 -125 -14 -9 -80 2 -305 49 -673
141 -768 160 -783 157 -8 -2 1 -8 20 -14 19 -6 152 -48 295 -93 143 -45 343
-108 445 -140 102 -33 197 -62 213 -66 15 -3 29 -10 32 -14 4 -6 238 -783 305
-1013 13 -42 27 -77 31 -77 5 0 9 324 9 720 0 396 3 720 8 720 4 0 65 -58 135
-128 l128 -128 -77 -345 c-147 -658 -161 -724 -156 -734 3 -5 9 2 13 15 13 43
144 454 231 729 l85 265 124 37 c316 94 842 250 916 272 46 13 86 26 89 30 4
4 -326 7 -734 7 -408 0 -742 3 -742 8 0 18 246 252 265 252 19 0 159 -29 804
-165 290 -61 279 -59 266 -46 -10 9 -144 52 -695 226 -151 47 -284 90 -296 95
-23 9 -29 27 -128 360 -106 359 -198 666 -216 720 -18 54 -18 47 -19 -687z"
        />
        <path
          d="M8680 2624 c-225 -42 -486 -183 -630 -341 -135 -148 -234 -343 -275
-541 -9 -46 -15 -85 -13 -88 4 -3 260 64 264 69 0 1 9 31 18 67 41 152 127
291 254 412 96 90 200 155 309 193 l68 24 38 102 c21 56 36 105 35 109 -2 4
-32 1 -68 -6z"
        />
        <path
          d="M9140 2629 c0 -8 14 -55 30 -105 l31 -91 93 -31 c137 -45 225 -101
341 -217 113 -113 175 -213 220 -352 l26 -81 132 -50 c73 -27 135 -47 138 -46
10 6 -16 132 -46 220 -59 176 -170 352 -295 467 -160 149 -399 261 -622 293
-37 5 -48 3 -48 -7z"
        />
        <path
          d="M2 2513 l3 -78 153 -3 152 -3 0 -964 0 -964 -152 -3 -153 -3 -3 -77
-3 -78 401 0 400 0 0 80 0 80 -150 0 -150 0 0 912 0 911 93 -124 c51 -68 382
-514 736 -991 l643 -868 119 0 119 0 0 1045 0 1045 140 0 140 0 0 80 0 80
-390 0 -390 0 0 -80 0 -80 155 0 155 0 0 -887 0 -886 -725 966 -725 967 -285
0 -286 0 3 -77z"
        />
        <path
          d="M3490 2510 l0 -79 129 -3 129 -3 -348 -960 -348 -960 -149 -5 -148
-5 -3 -77 -3 -78 406 0 405 0 0 80 0 79 -144 3 -144 3 99 280 c54 154 113 319
130 368 l32 87 424 -2 425 -3 129 -365 c71 -200 129 -365 129 -367 0 -2 -65
-3 -145 -3 l-145 0 0 -80 0 -80 415 0 416 0 -3 78 -3 77 -144 5 -143 5 -354
960 c-195 528 -354 961 -354 962 0 2 61 3 135 3 l135 0 0 80 0 80 -480 0 -480
0 0 -80z m658 -600 l180 -505 -183 -3 c-101 -1 -269 -1 -373 0 l-189 3 179
500 c98 275 181 506 183 514 4 9 9 11 15 5 5 -5 90 -236 188 -514z"
        />
        <path
          d="M5510 2510 l0 -80 160 0 160 0 0 -965 0 -965 -160 0 -160 0 0 -80 0
-80 440 0 440 0 0 80 0 80 -160 0 -160 0 0 460 0 460 370 0 370 0 0 -150 0
-150 65 0 65 0 0 370 0 370 -65 0 -65 0 0 -135 0 -135 -370 0 -370 0 0 420 0
420 575 0 575 0 0 -265 0 -265 90 0 90 0 0 345 0 345 -945 0 -945 0 0 -80z"
        />
        <path
          d="M12022 2513 l3 -78 128 -3 c70 -1 127 -6 127 -10 0 -4 -156 -438
-347 -964 l-347 -958 -148 0 -148 0 0 -80 0 -80 405 0 405 0 0 80 0 80 -145 0
c-80 0 -145 3 -145 8 0 4 58 169 128 367 l128 360 425 3 425 2 130 -367 131
-368 -146 -5 -146 -5 -3 -77 -3 -78 416 0 415 0 0 80 0 80 -142 0 -143 0 -353
958 c-194 526 -352 960 -352 964 0 4 60 9 133 10 l132 3 3 78 3 77 -486 0
-486 0 3 -77z m487 -110 c25 -64 351 -986 351 -994 0 -5 -165 -9 -371 -9 -293
0 -370 3 -367 13 3 6 85 238 184 515 98 276 182 502 185 502 4 0 12 -12 18
-27z"
        />
        <path
          d="M14060 2510 l0 -80 160 0 160 0 0 -965 0 -965 -160 0 -160 0 0 -80 0
-80 445 0 446 0 -3 78 -3 77 -162 3 -163 2 0 965 0 965 163 2 162 3 3 78 3 77
-446 0 -445 0 0 -80z"
        />
        <path
          d="M10014 1243 l-131 -35 -18 -70 c-36 -139 -119 -282 -229 -397 -104
-107 -227 -185 -357 -226 l-56 -18 -37 -98 c-20 -55 -36 -104 -36 -109 0 -6
10 -8 23 -6 145 30 192 42 252 63 324 117 567 372 680 717 30 92 56 218 44
215 -2 0 -63 -17 -135 -36z"
        />
        <path
          d="M7770 1244 c0 -31 29 -145 56 -218 119 -330 366 -572 699 -684 94
-32 221 -59 230 -50 3 3 -11 52 -32 108 l-38 103 -65 22 c-255 86 -474 312
-560 576 l-20 65 -123 44 c-67 23 -128 45 -134 48 -8 2 -13 -4 -13 -14z"
        />
        <path d="M10570 490 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z" />
      </g>
    </svg>
  );
};

export const CheebsToken = ({ fill = '#667dd1' }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 720.000000 720.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
      <path
        d="M3200 7189 c-340 -51 -552 -98 -710 -155 -36 -13 -72 -24 -79 -24 -7
0 -40 -11 -72 -25 -33 -14 -87 -37 -121 -51 -72 -29 -103 -44 -243 -111 -55
-26 -107 -52 -115 -59 -8 -6 -44 -26 -80 -45 -35 -19 -76 -44 -90 -56 -13 -13
-30 -23 -36 -23 -6 0 -21 -9 -34 -20 -13 -11 -29 -20 -37 -20 -7 0 -13 -3 -13
-7 0 -5 -17 -19 -37 -33 -54 -36 -204 -149 -231 -174 -13 -11 -33 -31 -46 -43
-13 -13 -26 -23 -30 -23 -9 0 -74 -63 -303 -296 -24 -24 -43 -46 -43 -50 0 -4
-10 -17 -22 -30 -13 -12 -30 -31 -40 -41 -22 -25 -148 -190 -178 -236 -14 -20
-28 -37 -32 -37 -5 0 -8 -6 -8 -13 0 -8 -9 -24 -20 -37 -11 -13 -20 -28 -20
-34 0 -6 -11 -23 -25 -38 -14 -15 -25 -32 -25 -37 0 -6 -10 -26 -22 -44 -134
-204 -334 -686 -384 -927 -3 -14 -16 -72 -29 -130 -13 -58 -28 -136 -34 -175
-41 -262 -42 -281 -39 -638 4 -304 7 -370 26 -482 26 -151 65 -332 93 -426 10
-36 19 -72 19 -80 0 -8 11 -41 23 -74 13 -33 32 -82 41 -110 42 -119 55 -153
64 -171 6 -10 32 -68 58 -129 26 -60 56 -121 65 -135 9 -14 24 -41 33 -60 9
-19 30 -57 46 -85 17 -27 33 -57 37 -65 4 -8 17 -29 30 -46 13 -17 23 -35 23
-41 0 -5 9 -18 20 -28 11 -10 20 -22 20 -27 0 -10 22 -41 73 -102 15 -19 27
-38 27 -44 0 -6 3 -12 8 -14 4 -1 30 -32 57 -68 28 -36 57 -72 65 -81 8 -9 33
-38 55 -64 32 -39 253 -262 315 -319 8 -7 60 -50 115 -94 55 -45 108 -88 117
-96 10 -9 22 -16 27 -16 5 0 15 -7 22 -16 12 -14 42 -37 107 -79 9 -5 45 -29
80 -52 35 -24 69 -43 77 -43 7 0 15 -4 17 -8 3 -8 192 -113 268 -148 175 -82
248 -114 262 -114 6 0 36 -11 67 -23 140 -59 547 -167 627 -167 16 0 81 -10
144 -22 105 -21 145 -23 455 -23 305 0 355 2 490 23 285 44 633 130 760 187
28 13 51 22 145 55 19 7 49 20 65 28 17 9 37 19 45 23 144 68 280 139 356 185
17 11 53 32 80 49 27 16 51 33 52 37 2 4 9 8 16 8 7 0 24 9 37 20 12 12 38 30
56 41 18 11 33 25 33 30 0 5 6 9 13 9 8 0 22 9 32 20 10 11 22 20 26 20 4 0
19 10 32 22 41 40 89 78 96 78 4 0 17 10 28 23 11 12 53 51 93 87 67 60 124
118 225 225 39 42 55 61 125 146 19 24 43 51 53 61 9 10 17 22 17 26 0 5 9 17
20 27 11 10 20 24 20 32 0 7 4 13 8 13 4 0 21 20 36 45 16 25 33 45 37 45 5 0
11 11 15 25 3 14 9 25 13 25 4 0 14 12 21 28 8 15 23 40 35 57 11 16 25 39 31
50 6 11 24 43 39 70 38 68 135 262 161 321 12 27 31 70 43 97 11 26 21 52 21
58 0 6 11 35 25 65 14 30 25 60 25 67 0 7 11 44 24 82 44 126 106 390 126 535
34 249 39 320 40 550 0 234 -6 312 -40 555 -29 197 -118 550 -171 671 -10 23
-21 55 -25 70 -4 16 -16 44 -26 62 -10 18 -18 38 -18 44 0 7 -15 44 -34 82
-19 39 -46 96 -60 126 -14 30 -30 62 -35 70 -6 8 -27 46 -48 83 -21 38 -48 85
-60 105 -12 21 -31 53 -42 72 -35 58 -44 71 -93 137 -27 34 -48 65 -48 68 0 3
-7 13 -16 23 -8 9 -50 60 -92 112 -111 136 -353 390 -372 390 -5 0 -13 7 -17
15 -7 12 -87 80 -236 201 -9 8 -21 14 -26 14 -5 0 -15 7 -22 16 -12 15 -53 44
-108 79 -9 6 -43 28 -76 50 -33 21 -69 44 -80 51 -11 6 -40 23 -65 37 -81 46
-369 187 -383 187 -7 0 -17 4 -23 9 -9 8 -70 33 -169 67 -27 9 -77 27 -110 39
-95 35 -374 103 -515 125 -231 36 -281 39 -605 39 -181 -1 -355 -5 -385 -10z
m755 -683 c446 -59 863 -210 1225 -443 241 -156 511 -397 679 -608 31 -38 59
-72 62 -75 3 -3 35 -48 71 -100 339 -494 506 -1000 525 -1590 11 -346 -35
-669 -142 -1000 -39 -122 -72 -202 -153 -380 -33 -72 -137 -248 -203 -344
-220 -320 -474 -575 -784 -785 -485 -330 -1045 -501 -1640 -501 -540 0 -1064
146 -1517 424 -97 60 -237 156 -284 195 -17 14 -51 42 -75 61 -57 45 -314 302
-359 359 -19 24 -47 58 -61 75 -91 109 -285 418 -332 526 -8 19 -27 62 -42 95
-44 97 -51 115 -88 221 -79 227 -124 441 -152 713 -19 193 -19 306 0 505 25
263 58 424 131 651 141 438 384 835 714 1165 478 479 1101 773 1786 845 165
17 473 13 639 -9z"
      />
      <path
        d="M3285 6054 c-976 -132 -1768 -804 -2053 -1739 -113 -368 -134 -828
-57 -1195 82 -393 233 -726 463 -1024 198 -256 487 -504 759 -652 37 -20 86
-47 108 -59 97 -53 396 -161 510 -185 160 -33 216 -42 339 -55 533 -57 1100
77 1561 368 376 238 679 571 883 969 141 276 222 554 257 883 19 181 19 302 0
479 -35 326 -124 625 -269 900 -122 233 -253 412 -431 591 -411 414 -910 656
-1490 724 -123 15 -458 12 -580 -5z m1509 -371 c29 -102 2 -213 -89 -359 -56
-89 -66 -115 -56 -151 4 -15 4 -46 -1 -67 -7 -30 -2 -75 22 -193 16 -84 33
-193 36 -241 7 -90 -7 -268 -31 -395 -22 -117 0 -162 134 -270 94 -76 206
-174 278 -243 61 -58 65 -79 31 -149 -19 -39 -21 -50 -11 -83 49 -160 53 -181
53 -264 0 -78 -2 -88 -22 -102 -12 -9 -37 -16 -55 -16 -44 0 -138 -86 -146
-133 -5 -33 -116 -269 -129 -275 -4 -2 -10 -20 -14 -40 -3 -20 -26 -79 -50
-129 -60 -126 -61 -134 -24 -228 28 -69 31 -89 27 -145 -4 -58 -1 -71 25 -118
24 -42 48 -65 125 -119 99 -68 123 -100 123 -161 0 -33 -38 -74 -90 -96 -87
-36 -378 -28 -474 14 -23 10 -55 33 -70 51 -36 43 -63 58 -102 59 -25 0 -39 9
-69 47 -36 46 -37 50 -32 112 4 50 1 67 -12 80 -36 38 -41 52 -56 172 -25 187
-57 302 -98 352 -124 149 -169 175 -257 144 -34 -13 -193 -129 -220 -161 -7
-9 -19 -16 -26 -16 -8 0 -14 -4 -14 -8 0 -5 -14 -24 -31 -43 -17 -19 -47 -54
-68 -79 -20 -25 -41 -49 -47 -55 -10 -9 -63 -103 -118 -210 -50 -97 -64 -142
-70 -230 -6 -76 -11 -96 -34 -130 -15 -22 -45 -50 -67 -62 -21 -12 -45 -34
-53 -50 -23 -45 -79 -89 -176 -140 l-91 -47 -135 0 c-160 -1 -184 4 -210 47
-36 58 -17 166 39 224 12 12 21 26 21 32 0 5 7 11 15 14 20 8 58 72 78 129 13
39 13 49 2 58 -22 18 -28 67 -15 123 7 29 16 57 20 62 12 15 40 175 40 224 0
23 -7 96 -16 162 -12 98 -22 135 -55 207 -34 72 -136 262 -142 262 -41 1 -129
25 -161 44 -33 20 -46 36 -65 83 -13 32 -49 109 -80 172 -54 109 -56 118 -60
205 -3 98 10 179 41 239 35 69 129 180 263 309 94 90 124 125 135 153 5 14 9
89 8 167 l-2 143 57 117 c31 64 57 121 57 127 0 5 22 56 49 113 93 196 107
275 76 418 -28 129 -19 155 61 178 87 25 184 11 268 -38 40 -24 43 -24 191 8
164 36 478 45 906 28 l205 -9 86 56 c47 31 94 60 104 64 11 3 37 18 59 31 52
33 79 40 147 41 46 1 58 -2 62 -16z"
      />
    </g>
  </svg>
);
